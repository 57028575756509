import PasswordValidator from 'password-validator';

const passwordSchemaGenerator = (passwordConfig) => {
  let schema = new PasswordValidator();
  for (const characterRule in passwordConfig?.charactersRestrictions) {
    if (passwordConfig?.charactersRestrictions[characterRule] !== null) {
      switch (characterRule) {
        case 'maxCharacters':
          schema.is().max(passwordConfig.charactersRestrictions[characterRule]);
          break;
        case 'minCharacters':
          schema.is().min(passwordConfig.charactersRestrictions[characterRule]);
          break;
        case 'minDigits':
          schema
            .has()
            .digits(passwordConfig.charactersRestrictions[characterRule]);
          break;
        case 'minSpecialCharacters':
          schema
            .has()
            .symbols(passwordConfig.charactersRestrictions[characterRule]);
          break;
        case characterRule === 'minLowerCaseCharacters':
          schema
            .has()
            .lowercase(
              passwordConfig.charactersRestrictions[characterRule] ?? 1,
            );
          break;
        case characterRule === 'minUpperCaseCharacters':
          schema
            .has()
            .uppercase(
              passwordConfig.charactersRestrictions[characterRule] ?? 1,
            );
          break;
        default:
          break;
      }
    }
  }

  if (passwordConfig.sapcesNotAllowed) {
    if (passwordConfig.sapcesNotAllowed === true) {
      schema.has().not().spaces(0, 'No WhiteSpaces Allowed');
    }
  }

  if (passwordConfig.blackListWords) {
    if (
      passwordConfig.blackListWords.length > 0 &&
      Array.isArray(passwordConfig.blackListWords)
    ) {
      schema
        .is()
        .not()
        .oneOf([...passwordConfig.blackListWords], 'Try Different Password');
    }
  }
  return schema;
};

export default passwordSchemaGenerator;
