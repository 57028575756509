import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SUNDRIES_API_PATH } from '../../../constants/apiPaths';
import {
  experiencesEpFactory,
  placesEpFactory,
  skillsEpFactory,
  createFeedbackEpFactory,
  suggestedSkillsEpFactory,
} from './endpoints';

export const sundriesApi = createApi({
  reducerPath: 'sundriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${SUNDRIES_API_PATH}/api/v1`,
  }),
  endpoints: (builder) => ({
    findExperiences: experiencesEpFactory(builder),
    findPlaces: placesEpFactory(builder),
    findSkills: skillsEpFactory(builder),
    createFeedback: createFeedbackEpFactory(builder),
    getSuggestedSkills: suggestedSkillsEpFactory(builder),
  }),
});

export const {
  useGetSuggestedSkillsMutation,
  useLazyFindExperiencesQuery,
  useFindExperiencesQuery,
  useFindPlacesQuery,
  useLazyFindPlacesQuery,
  useFindSkillsQuery,
  useLazyFindSkillsQuery,
  useCreateFeedbackMutation,
} = sundriesApi;
