export default (builder) =>
  builder.query({
    query: (text) => ({ url: `jd/parse`, method: 'POST', body: text }),
    transformResponse: (response) => {
      const jobTitle = response.jobTitle === 'null' ? null : response.jobTitle;

      const skills = {
        values: [],
        weight: response.primarySkills.weight,
      };

      const locations = {
        values: [],
        weight: response.jobLocations.weight,
      };

      const experiences = {
        values: [],
        weight: 7,
      };

      skills.values = skills.values.concat(
        response.primarySkills.values.map((item) => {
          item.label = item.value;
          item.type = 'primarySkill';
          return item;
        }),
      );

      skills.values = skills.values.concat(
        response.secondarySkills.values.map((item) => {
          item.label = item.value;
          item.type = 'secondarySkill';
          return item;
        }),
      );

      skills.values = skills.values.concat(
        response.managerialSkills.values.map((item) => {
          item.label = item.value;
          item.type = 'managerialSkill';
          return item;
        }),
      );

      skills.values = skills.values.concat(
        response.softSkills.values.map((item) => {
          item.label = item.value;
          item.type = 'softSkill';
          return item;
        }),
      );

      locations.values = locations.values.concat(
        response.jobLocations.values.map((item) => {
          item.label = item.value;
          return item;
        }),
      );

      experiences.values = [response.overallYearsOfExp];

      return {
        jobTitle,
        skills,
        locations,
        // experiences,
      };
    },
  });
