import { FormControlLabel, Checkbox } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const CustomCheckbox = ({ label, ...props }) =>
  label ? (
    <FormControlLabel control={<Checkbox {...props} />} label={label} />
  ) : (
    <Checkbox {...props} />
  );

CustomCheckbox.propTypes = {
  label: PropTypes.string,
};

export default CustomCheckbox;
