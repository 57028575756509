const {
  REACT_APP_PATH_ROOT,
  REACT_APP_PATH_ALL_OTHER_ROOTS,
  REACT_APP_PATH_LOGIN,
  REACT_APP_PATH_SEARCH,
  REACT_APP_PATH_USERS,
  REACT_APP_PATH_CANDIDATE,
  REACT_APP_PATH_PRIVACY,
  REACT_APP_PATH_TERMS,
  REACT_APP_PATH_HELP,
  REACT_APP_PATH_FEEDBACK,
  REACT_APP_PATH_JOB_PROFILES,
  REACT_APP_PATH_ACCOUNT,
  REACT_APP_PATH_SIGNUP,
  REACT_APP_PATH_SIGNUP_CONFIRM_EMAIL,
  REACT_APP_PATH_PROFILE,
  REACT_APP_PATH_ACCOUNT_SMTP,
  REACT_APP_PATH_ORGS,
  REACT_APP_PATH_JOBLIST,
  REACT_APP_PATH_CONVERSATIONS,
  REACT_APP_PATH_ORGCONFIG,
  REACT_APP_PATH_ADDCONFIG,
  REACT_APP_PATH_ADDORG,
  REACT_APP_PATH_CONFIGS,
  REACT_APP_PATH_SCRAPER,
  REACT_APP_PATH_UNAUTHORISED,
  REACT_APP_PATH_CREATE_ORGANISATION,
  REACT_APP_PATH_ORGANISATION,
  REACT_APP_PATH_SELECT_LICENSE_TIER,
} = process.env;

export const ROOT = REACT_APP_PATH_ROOT;
export const ALL_OTHER_ROUTES = REACT_APP_PATH_ALL_OTHER_ROOTS;
export const LOGIN = REACT_APP_PATH_LOGIN;
export const SEARCH = REACT_APP_PATH_SEARCH;
export const USERS = REACT_APP_PATH_USERS;
export const CANDIDATE = REACT_APP_PATH_CANDIDATE;
export const PRIVACY = REACT_APP_PATH_PRIVACY;
export const TERMS = REACT_APP_PATH_TERMS;
export const HELP = REACT_APP_PATH_HELP;
export const FEEDBACK = REACT_APP_PATH_FEEDBACK;
export const JOB_PROFILES = REACT_APP_PATH_JOB_PROFILES;
export const ACCOUNT = REACT_APP_PATH_ACCOUNT;
export const SIGN_UP = REACT_APP_PATH_SIGNUP;
export const SIGNUP_CONFIRM_EMAIL = REACT_APP_PATH_SIGNUP_CONFIRM_EMAIL;
export const PROFILE = REACT_APP_PATH_PROFILE;
export const SMTP = REACT_APP_PATH_ACCOUNT_SMTP;
export const ORGS = REACT_APP_PATH_ORGS;
export const JOBLIST = REACT_APP_PATH_JOBLIST;
export const CONVERSATIONS = REACT_APP_PATH_CONVERSATIONS;
export const ORGSCONFIG = REACT_APP_PATH_ORGCONFIG;
export const ADDCONFIG = REACT_APP_PATH_ADDCONFIG;
export const ADDORG = REACT_APP_PATH_ADDORG;
export const CONFIGS = REACT_APP_PATH_CONFIGS;
export const SCRAPER = REACT_APP_PATH_SCRAPER;
export const UNAUTHORISED = REACT_APP_PATH_UNAUTHORISED;
export const CREATE_ORGANISATION = REACT_APP_PATH_CREATE_ORGANISATION;
export const ORGANISATOIN = REACT_APP_PATH_ORGANISATION;
export const SELECT_LICENSE_TIER = REACT_APP_PATH_SELECT_LICENSE_TIER;
