import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '../';
import { useStyles } from './lozenge.style';
import clsx from 'clsx';

const Lozenge = ({ label, color }) => {
  const classes = useStyles();

  return (
    <Box
      component="div"
      className={clsx(
        classes.box,
        { [classes.lightGrey]: color === 'lightGrey' },
        { [classes.darkGrey]: color === 'darkGrey' },
        { [classes.lightBlue]: color === 'lightBlue' },
        { [classes.darkBlue]: color === 'darkBlue' },
        { [classes.lightGreen]: color === 'lightGreen' },
        { [classes.darkGreen]: color === 'darkGreen' },
        { [classes.lightPurple]: color === 'lightPurple' },
        { [classes.darkPurple]: color === 'darkPurple' },
        { [classes.lightRed]: color === 'lightRed' },
        { [classes.darkRed]: color === 'darkRed' },
        { [classes.lightYellow]: color === 'lightYellow' },
        { [classes.darkYellow]: color === 'darkYellow' },
        { [classes.lightTeal]: color === 'lightTeal' },
        { [classes.darkTeal]: color === 'darkTeal' },
      )}
    >
      <Typography
        variant="subtitle2"
        component="span"
        className={classes.label}
      >
        {label}
      </Typography>
    </Box>
  );
};

Lozenge.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Lozenge.defaultProps = {
  color: 'lightGrey',
};

export default Lozenge;
