const passwordPolicyTextGenerator = (passwordConfig) => {
  let passwordPolicyTextPoints = [];
  for (const characterRule in passwordConfig?.charactersRestrictions) {
    if (
      passwordConfig.charactersRestrictions[characterRule] !== null &&
      passwordConfig.charactersRestrictions[characterRule] >= 0
    ) {
      passwordPolicyTextPoints.push(
        `${passwordConfig.charactersRestrictions[`${characterRule}Text`]} ${
          passwordConfig.charactersRestrictions[characterRule]
        }`,
      );
    }
  }
  return passwordPolicyTextPoints;
};

export default passwordPolicyTextGenerator;
