import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  rectangled: {
    borderRadius: '3px !important',
  },
  lightBlue: {
    color: `${theme.palette.blue.B500} !important`,
    backgroundColor: `${theme.palette.blue.B75} !important`,
  },
  darkBlue: {
    color: `${theme.palette.neutralLight.N800} !important`,
    backgroundColor: `${theme.palette.blue.B100} !important`,
  },
  lightGreen: {
    color: `${theme.palette.green.G500} !important`,
    backgroundColor: `${theme.palette.green.G100} !important`,
  },
  darkGreen: {
    color: `${theme.palette.neutralLight.N800} !important`,
    backgroundColor: `${theme.palette.green.G200} !important`,
  },
  lightPurple: {
    color: `${theme.palette.purple.P500} !important`,
    backgroundColor: `${theme.palette.purple.P75} !important`,
  },
  darkPurple: {
    color: `${theme.palette.neutralLight.N800} !important`,
    backgroundColor: `${theme.palette.purple.P100} !important`,
  },
  lightRed: {
    color: `${theme.palette.neutralLight.N500} !important`,
    backgroundColor: `${theme.palette.red.R75} !important`,
  },
  darkRed: {
    color: `${theme.palette.neutralLight.N800} !important`,
    backgroundColor: `${theme.palette.red.R100} !important`,
  },
  lightYellow: {
    color: `${theme.palette.neutralLight.N500} !important`,
    backgroundColor: `${theme.palette.yellow.Y100} !important`,
  },
  darkYellow: {
    color: `${theme.palette.neutralLight.N800} !important`,
    backgroundColor: `${theme.palette.yellow.Y200} !important`,
  },
  lightTeal: {
    color: `${theme.palette.neutralLight.N500} !important`,
    backgroundColor: `${theme.palette.teal.T100} !important`,
  },
  darkTeal: {
    color: `${theme.palette.neutralLight.N800} !important`,
    backgroundColor: `${theme.palette.teal.T200} !important`,
  },
}));
