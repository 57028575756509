const {
  REACT_APP_AUTH_URL,
  REACT_APP_AUTH_REALM,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REDIRECT_URI,
} = process.env;

export const AUTH_URL = REACT_APP_AUTH_URL;
export const REALM = REACT_APP_AUTH_REALM;
export const AUTHORITY = `${AUTH_URL}/realms/${REALM}/`;
export const CLIENT_ID = REACT_APP_AUTH_CLIENT_ID;
export const REDIRECT_URI = REACT_APP_AUTH_REDIRECT_URI;
