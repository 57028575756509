import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: 3,
    display: 'inline-flex',
    padding: '0 4px',
  },
  label: {
    textTransform: 'uppercase',
    color: 'inherit !important',
  },
  lightGrey: {
    color: `${theme.palette.neutralLight.N500} !important`,
    backgroundColor: `${theme.palette.neutralLight.N40} !important`,
  },
  darkGrey: {
    color: `${theme.palette.neutralLight.N0} !important`,
    backgroundColor: `${theme.palette.neutralLight.N500} !important`,
  },
  lightBlue: {
    color: `${theme.palette.blue.B500} !important`,
    backgroundColor: `${theme.palette.blue.B50} !important`,
  },
  darkBlue: {
    color: `${theme.palette.neutralLight.N0} !important`,
    backgroundColor: `${theme.palette.blue.B400} !important`,
  },
  lightGreen: {
    color: `${theme.palette.green.G500} !important`,
    backgroundColor: `${theme.palette.green.G50} !important`,
  },
  darkGreen: {
    color: `${theme.palette.neutralLight.N0} !important`,
    backgroundColor: `${theme.palette.green.G400} !important`,
  },
  lightPurple: {
    color: `${theme.palette.purple.P500} !important`,
    backgroundColor: `${theme.palette.purple.P50} !important`,
  },
  darkPurple: {
    color: `${theme.palette.neutralLight.N0} !important`,
    backgroundColor: `${theme.palette.purple.P400} !important`,
  },
  lightRed: {
    color: `${theme.palette.red.R500} !important`,
    backgroundColor: `${theme.palette.red.R50} !important`,
  },
  darkRed: {
    color: `${theme.palette.neutralLight.N0} !important`,
    backgroundColor: `${theme.palette.red.R400} !important`,
  },
  lightYellow: {
    color: `${theme.palette.neutralLight.N800} !important`,
    backgroundColor: `${theme.palette.yellow.Y75} !important`,
  },
  darkYellow: {
    color: `${theme.palette.neutralLight.N800} !important`,
    backgroundColor: `${theme.palette.yellow.Y500} !important`,
  },
  lightTeal: {
    color: `${theme.palette.teal.T500} !important`,
    backgroundColor: `${theme.palette.teal.T50} !important`,
  },
  darkTeal: {
    color: `${theme.palette.neutralLight.N0} !important`,
    backgroundColor: `${theme.palette.teal.T400} !important`,
  },
}));
