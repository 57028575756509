import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

const PasswordPolicyPopper = ({ passwordPolicyPoints }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setTimeout(() => {
      setAnchorEl(null);
    }, 5000);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <InfoIcon fontSize="small" color="primary" onClick={handleClick} />

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={'bottom-start'}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={500}>
            <Box
              sx={{
                border: 1,
                display: 'flex',
                flexDirection: 'column',
                p: 1,
                bgcolor: 'background.paper',
              }}
            >
              {passwordPolicyPoints.map((point, index) => (
                <div key={index}>{point}</div>
              ))}
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};

PasswordPolicyPopper.propTypes = {
  passwordPolicyPoints: [],
};

export default PasswordPolicyPopper;
