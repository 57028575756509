import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.neutralLight.N900,
  },
  link: {
    color: theme.palette.neutralLight.N0,
  },
}));
