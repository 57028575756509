const muiCheckbox = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      padding: 4,
    },
  },
};

export default muiCheckbox;
