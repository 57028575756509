import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { mlApi } from './services/ml';
import { peopleApi } from './services/people';
import { jobProfileApi } from './services/jobProfile';
import { userApi } from './services/user';
import { sundriesApi } from './services/sundries';
import { smtpApi } from './services/smtp';
import { emailApi } from './services/email';
import { jdScraperApi } from './services/jdScraper';
import { organisationApi } from './services/organisation';
import { licenseApi } from './services/license/license.api';

import authReducer from './reducers/auth.slice';

const store = configureStore({
  reducer: {
    [mlApi.reducerPath]: mlApi.reducer,
    [peopleApi.reducerPath]: peopleApi.reducer,
    [jobProfileApi.reducerPath]: jobProfileApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [sundriesApi.reducerPath]: sundriesApi.reducer,
    [smtpApi.reducerPath]: smtpApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [jdScraperApi.reducerPath]: jdScraperApi.reducer,
    [organisationApi.reducerPath]: organisationApi.reducer,
    [licenseApi.reducerPath]: licenseApi.reducer,
    auth: authReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      mlApi.middleware,
      peopleApi.middleware,
      jobProfileApi.middleware,
      userApi.middleware,
      sundriesApi.middleware,
      smtpApi.middleware,
      emailApi.middleware,
      organisationApi.middleware,
      licenseApi.middleware,
      jdScraperApi.middleware,
    ),
});

export default store;

setupListeners(store.dispatch);
