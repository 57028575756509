const muiFormHelperText = {
  styleOverrides: {
    root: {
      margin: 0,
      marginTop: 4,
    },
  },
};

export default muiFormHelperText;
