import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SMTP_API_PATH } from '../../../constants/apiPaths';
import {
  createSMTPConfigFactory,
  getSMTPConfigFactory,
  createSMTPTestConfigFactory,
} from './endpoints';

export const smtpApi = createApi({
  reducerPath: 'smtpApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SMTP_API_PATH,
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createSMTPConfig: createSMTPConfigFactory(builder),
    getSMTPConfig: getSMTPConfigFactory(builder),
    testSMTPConfig: createSMTPTestConfigFactory(builder),
  }),
});

export const {
  useCreateSMTPConfigMutation,
  useGetSMTPConfigQuery,
  useTestSMTPConfigMutation,
} = smtpApi;
