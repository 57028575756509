import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Stack, Link } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useAuth } from 'react-oidc-context';
import { Button, IconButton, Typography } from '../../basic';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { JOB_PROFILES, SIGN_UP } from '../../../constants/paths';
import * as PATHS from '../../../constants/paths';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const AppHeader = ({ innerRef }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!auth.isLoading && auth.isAuthenticated) {
      setSearchParams({});
    }
  }, [auth.isAuthenticated, auth.isLoading]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleProfileClick = () => {
    handleMenuClose();
    navigate(`${PATHS.ACCOUNT}${PATHS.PROFILE}`);
  };

  const handleAccountClick = () => {
    handleMenuClose();
    navigate(PATHS.ACCOUNT);
  };

  const handleJobProfilesClick = () => {
    navigate(JOB_PROFILES);
  };

  const handleRegisterClick = () => {
    handleMenuClose();
    navigate(SIGN_UP);
  };

  const handleLogin = () => {
    auth.signinRedirect();
  };

  const handleLogout = () => {
    auth.signoutRedirect();
    if (auth.isAuthenticated) {
      auth.removeUser();
    }
    handleMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled>{auth.user?.profile.email}</MenuItem>
      <MenuItem onClick={handleAccountClick}>Account</MenuItem>
      <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {!auth.isAuthenticated && (
        <Stack>
          <MenuItem onClick={handleLogin}>
            <IconButton size="large" aria-label="login" color="inherit">
              <LoginIcon />
            </IconButton>
            <Typography variant="body1">Login</Typography>
          </MenuItem>
          <MenuItem onClick={handleRegisterClick}>
            <IconButton size="large" aria-label="sign up" color="inherit">
              <PersonAddIcon />
            </IconButton>
            <Typography variant="body1">Register</Typography>
          </MenuItem>
        </Stack>
      )}
      {auth.isAuthenticated && (
        <Stack>
          <MenuItem>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <Typography variant="body1">Messages</Typography>
          </MenuItem>
          <MenuItem>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Typography variant="body1">Notifications</Typography>
          </MenuItem>
          <MenuItem onClick={handleProfileMenuOpen}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Typography variant="body1">Profile</Typography>
          </MenuItem>
        </Stack>
      )}
    </Menu>
  );

  return (
    <>
      <AppBar
        elevation={1}
        position="sticky"
        color="secondary"
        sx={{ borderRadius: 0 }}
        ref={innerRef}
      >
        <Toolbar variant="dense">
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography
            variant="h4"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            Rikroot
          </Typography>
          {auth.isAuthenticated && (
            <Stack spacing={2} direction={'row'}>
              <Button
                variant="text"
                color="primary"
                onClick={handleJobProfilesClick}
              >
                Job Profiles
              </Button>
            </Stack>
          )}
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {auth.isAuthenticated && (
              <>
                <IconButton
                  size="large"
                  aria-label="No new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={0} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </>
            )}
            {!auth.isAuthenticated && (
              <Stack spacing={2} direction={'row'}>
                <Button color="primary" onClick={handleRegisterClick}>
                  Register
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </Stack>
            )}
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </>
  );
};

AppHeader.propTypes = {
  innerRef: PropTypes.any,
};

export default React.forwardRef((props, ref) => (
  <AppHeader innerRef={ref} {...props} />
));
