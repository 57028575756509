import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { EMAIL_API_PATH } from '../../../constants/apiPaths';
import {
  sendEmailEpFactory,
  recruiterConversationsEpFactory,
  individualCandidateConversationsEpFactory,
} from './endpoints';

export const emailApi = createApi({
  reducerPath: 'emailApi',
  baseQuery: fetchBaseQuery({ baseUrl: EMAIL_API_PATH }),
  endpoints: (builder) => ({
    sendEmail: sendEmailEpFactory(builder),
    getRecruiterConversations: recruiterConversationsEpFactory(builder),
    getIndividualCandidateConversations:
      individualCandidateConversationsEpFactory(builder),
  }),
});

export const {
  useGetRecruiterConversationsQuery,
  useGetIndividualCandidateConversationsQuery,
  useLazyGetRecruiterConversationsQuery,
  useLazyGetIndividualCandidateConversationsQuery,
  useSendEmailMutation,
} = emailApi;
