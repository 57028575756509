import colors from '../colors';
import fontWeights from '../fontWeights';

const muiButton = {
  defaultProps: {
    color: 'secondary',
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      borderRadius: 3,
      fontSize: '1rem',
      fontWeight: fontWeights.semiBold,
      lineHeight: '1.429rem',
      textTransform: 'none',
      '&:disabled': {
        color: colors.neutralLight.N70,
        backgroundColor: colors.neutralLightAlpha.N20A,
      },
    },
    containedSuccess: {
      color: colors.neutralLight.N0,
      backgroundColor: colors.green.G400,
      '&:hover': {
        color: colors.neutralLight.N0,
        backgroundColor: colors.green.G300,
      },
      '&:active': {
        backgroundColor: colors.green.G500,
      },
    },
    containedError: {
      color: colors.neutralLight.N0,
      backgroundColor: colors.red.R400,
      '&:hover': {
        color: colors.neutralLight.N0,
        backgroundColor: colors.red.R300,
      },
      '&:active': {
        backgroundColor: colors.red.R500,
      },
    },
    containedWarning: {
      color: colors.neutralLight.N800,
      backgroundColor: colors.yellow.Y300,
      '&:hover': {
        color: colors.neutralLight.N800,
        backgroundColor: colors.yellow.Y200,
      },
      '&:active': {
        backgroundColor: colors.yellow.Y400,
      },
    },
    containedSecondary: {
      color: colors.neutralLight.N500,
      backgroundColor: colors.neutralLightAlpha.N20A,
      '&:hover': {
        color: colors.neutralLight.N500,
        backgroundColor: colors.neutralLightAlpha.N30A,
      },
      '&:active': {
        color: colors.blue.B500,
        backgroundColor: colors.blue.B50,
      },
    },
    textSecondary: {
      color: colors.neutralLight.N500,
      '&:hover': {
        color: colors.neutralLight.N500,
        backgroundColor: colors.neutralLightAlpha.N30A,
      },
      '&:active': {
        color: colors.blue.B500,
        backgroundColor: colors.blue.B50,
      },
    },
    containedPrimary: {
      color: colors.neutralLight.N0,
      backgroundColor: colors.blue.B400,
      '&:hover': {
        color: colors.neutralLight.N0,
        backgroundColor: colors.blue.B300,
      },
      '&:active': {
        backgroundColor: colors.blue.B500,
      },
    },
    containedDefault: {
      color: colors.neutralLight.N500,
      backgroundColor: colors.neutralLightAlpha.N20A,
      '&:hover': {
        color: colors.neutralLight.N500,
        backgroundColor: colors.neutralLightAlpha.N30A,
      },
      // disabled
      // because the focus gets lock on click causing the button state to remain focused
      //
      // '&:focus': {
      //   color: customColors.neutralLight.N0,
      //   backgroundColor: customColors.neutralLight.N700,
      // },
      '&:active': {
        color: colors.blue.B500,
        backgroundColor: colors.blue.B50,
      },
    },
    sizeLarge: {
      padding: '10px 12px',
    },
    sizeMedium: {
      padding: '6px 12px',
    },
    sizeSmall: {
      padding: '2px 12px',
    },
    startIcon: {
      '& > :nth-of-type(1)': {
        fontSize: '1rem',
      },
    },
    endIcon: {
      '& > :nth-of-type(1)': {
        fontSize: '1rem',
      },
    },
  },
};

export default muiButton;
