import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '../../../pages/error.page';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Caught Unhandled Exception: ', error, errorInfo);
    return this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorHandler;
