export default (builder) =>
  builder.query({
    query: (text) => ({
      url: `places/search?input=${text}`,
      method: 'GET',
    }),
    transformResponse: (response) => {
      return response.map((place) => ({
        label: place,
        value: place,
        datatype: 'string',
        weight: 7,
      }));
    },
  });
