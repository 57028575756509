import React from 'react';
import Chip from '@mui/material/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './tag.style';
import { useTheme } from '@emotion/react';

const Tag = ({ shape, action, onAction, color, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Chip
      className={clsx(
        { [classes.rectangled]: shape === 'rectangle' },
        { [classes.lightBlue]: color === 'lightBlue' },
        { [classes.darkBlue]: color === 'darkBlue' },
        { [classes.lightGreen]: color === 'lightGreen' },
        { [classes.darkGreen]: color === 'darkGreen' },
        { [classes.lightPurple]: color === 'lightPurple' },
        { [classes.darkPurple]: color === 'darkPurple' },
        { [classes.lightRed]: color === 'lightRed' },
        { [classes.darkRed]: color === 'darkRed' },
        { [classes.lightYellow]: color === 'lightYellow' },
        { [classes.darkYellow]: color === 'darkYellow' },
        { [classes.lightTeal]: color === 'lightTeal' },
        { [classes.darkTeal]: color === 'darkTeal' },
      )}
      deleteIcon={
        action === 'delete' ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faPlus} />
        )
      }
      onDelete={action !== 'none' ? (e) => onAction(e) : undefined}
      color={theme.palette[color] !== undefined ? color : undefined}
      {...props}
    ></Chip>
  );
};

Tag.propTypes = {
  shape: PropTypes.oneOf(['rounded', 'rectangle']),
  action: PropTypes.oneOf(['none', 'add', 'delete']),
  onAction: PropTypes.func,
  color: PropTypes.string,
};

Tag.defaultProps = {
  shape: 'rounded',
  action: 'none',
  onAction: () => {},
};

export default Tag;
