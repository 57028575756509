import React, { Suspense, lazy, useRef, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as PATHS from '../constants/paths';
import ErrorHandler from '../components/system/error.handler';
import { CircularProgressIndicator } from '../components/basic';
import AppHeader from '../components/advanced/app.header';
import AppFooter from '../components/advanced/app.footer';
import { AuthProvider } from 'react-oidc-context';
import { AUTHORITY, CLIENT_ID, REDIRECT_URI } from '../constants/auth';
import ROLES from '../constants/roles';
import getWithAuth from './getWithAuth.component';

const UnauthorisedPage = lazy(() => import('../pages/unauthorised.page'));
const SearchPage = lazy(() => import('../pages/search.page'));
const CandidatePage = lazy(() => import('../pages/candidate.page'));
const HomePage = lazy(() => import('../pages/home.page'));
const PrivacyPage = lazy(() => import('../pages/privacy.page'));
const TermsPage = lazy(() => import('../pages/terms.page'));
const HelpPage = lazy(() => import('../pages/help.page'));
const FeedbackPage = lazy(() => import('../pages/feedback.page'));
const NotFoundPage = lazy(() => import('../pages/notFound.page'));
const JobProfilesPage = lazy(() => import('../pages/jobProfiles.page'));
const AccountRoutes = lazy(() => import('../pages/account.page'));
const SignUpPage = lazy(() => import('../pages/signup.page'));
const ConfirmEmailSentPage = lazy(() =>
  import('../pages/confirmemailsent.page'),
);
const ConversationsPage = lazy(() => import('../pages/conversations.page'));
const CandidateConversationsPage = lazy(() =>
  import('../pages/candidateConversations.page'),
);
const CreateOrganisationPage = lazy(() =>
  import('../pages/create.organisation.page'),
);
const OrganisationDetailsPage = lazy(() =>
  import('../pages/organisation.details.page'),
);
const LicenseTierPage = lazy(() => import('../pages/license.tier.page'));

const oidcConfig = {
  authority: AUTHORITY,
  client_id: CLIENT_ID,
  redirect_uri: REDIRECT_URI,
};

const AppRoutes = () => {
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const [headerFooterHeight, setHeaderFooterHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      setTimeout(() => {
        setHeaderFooterHeight(
          headerRef.current.offsetHeight + footerRef.current.offsetHeight,
        );
      }, 200);
    }
  }, [headerRef, footerRef]);

  return (
    <ErrorHandler>
      <AuthProvider {...oidcConfig}>
        <Suspense fallback={<CircularProgressIndicator loading="loading" />}>
          <BrowserRouter>
            <AppHeader ref={headerRef} />
            <div
              style={{
                minHeight: `calc(100vh - ${headerFooterHeight}px)`,
              }}
            >
              <Routes>
                <Route
                  path={PATHS.UNAUTHORISED}
                  element={<UnauthorisedPage />}
                />
                <Route path={PATHS.ROOT} element={<HomePage />} />
                <Route path={PATHS.SEARCH} element={<SearchPage />} />
                <Route path={PATHS.CANDIDATE} element={<CandidatePage />} />
                <Route path={PATHS.PRIVACY} element={<PrivacyPage />} />
                <Route path={PATHS.TERMS} element={<TermsPage />} />
                <Route path={PATHS.HELP} element={<HelpPage />} />
                <Route path={PATHS.FEEDBACK} element={<FeedbackPage />} />
                <Route
                  path={PATHS.JOB_PROFILES}
                  element={<JobProfilesPage />}
                />
                <Route
                  path={`${PATHS.ACCOUNT}/*`}
                  element={getWithAuth(
                    AccountRoutes,
                    [
                      ROLES.ORG_USER,
                      ROLES.SUPER_ADMIN,
                      ROLES.NORMAL_USER,
                      ROLES.ORG_ADMIN,
                    ],
                    {},
                    true,
                  )}
                />
                <Route
                  path={PATHS.CREATE_ORGANISATION}
                  element={getWithAuth(
                    CreateOrganisationPage,
                    [ROLES.ORG_ADMIN, ROLES.NORMAL_USER, ROLES.SUPER_ADMIN],
                    {},
                    true,
                  )}
                />
                <Route
                  path={`${PATHS.ORGANISATOIN}/:organisationId`}
                  element={getWithAuth(
                    OrganisationDetailsPage,
                    [ROLES.ORG_ADMIN, ROLES.SUPER_ADMIN, ROLES.ORG_USER],
                    {},
                    true,
                  )}
                />
                <Route
                  path={PATHS.SELECT_LICENSE_TIER}
                  element={getWithAuth(
                    LicenseTierPage,
                    [ROLES.ORG_ADMIN, ROLES.NORMAL_USER, ROLES.SUPER_ADMIN],
                    {},
                    true,
                  )}
                />
                <Route
                  path={PATHS.SIGNUP_CONFIRM_EMAIL}
                  element={<ConfirmEmailSentPage />}
                />
                <Route path={PATHS.SIGN_UP} element={<SignUpPage />} />
                <Route
                  path={PATHS.CONVERSATIONS}
                  element={<ConversationsPage />}
                />
                <Route
                  path={`${PATHS.CONVERSATIONS}/:candidateID`}
                  element={<CandidateConversationsPage />}
                />
                <Route
                  path={PATHS.ALL_OTHER_ROUTES}
                  element={<NotFoundPage />}
                />
              </Routes>
            </div>
            <AppFooter ref={footerRef} />
          </BrowserRouter>
        </Suspense>
      </AuthProvider>
    </ErrorHandler>
  );
};

export default AppRoutes;
