export default (builder) =>
  builder.query({
    query: (args) => {
      return {
        url: 'email/candidateEmails',
        method: 'GET',
        params: { candidateID: args.candidateID },
      };
    },
  });
