import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  rikroottitle: {
    padding: theme.spacing(2, 0),
  },
  formContainer: {
    borderRadius: '5px',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  formTitle: {
    padding: theme.spacing(4, 0),
  },
}));
