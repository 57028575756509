import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PEOPLE_API_PATH } from '../../../constants/apiPaths';
import {
  candidateEpFactory,
  industriesEpFactory,
  searchEpFactory,
  candidateEmailIdsEpFactory,
} from './endpoints';
import { User } from 'oidc-client-ts';
import { AUTHORITY, CLIENT_ID } from '../../../constants/auth';

function getUser() {
  const oidcUser = sessionStorage.getItem(
    `oidc.user:${AUTHORITY}:${CLIENT_ID}`,
  );
  if (!oidcUser) {
    return null;
  }

  return User.fromStorageString(oidcUser);
}

export const peopleApi = createApi({
  reducerPath: 'jobProfileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${PEOPLE_API_PATH}/api/v1`,
    prepareHeaders: (headers) => {
      const user = getUser();
      const token = user?.access_token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createSearch: searchEpFactory(builder),
    getCandidateWithId: candidateEpFactory(builder),
    getAllIndustries: industriesEpFactory(builder),
    getCandidateEmailIds: candidateEmailIdsEpFactory(builder),
  }),
});

export const {
  useCreateSearchMutation,
  useGetCandidateWithIdQuery,
  useGetAllIndustriesQuery,
  useLazyGetAllIndustriesQuery,
  useGetCandidateEmailIdsQuery,
} = peopleApi;
