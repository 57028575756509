const muiCssBaseline = {
  styleOverrides: `
    @font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
    }
  `,
};

export default muiCssBaseline;
