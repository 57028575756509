import React, { useEffect } from 'react';
import { useStyles } from './signup.page.style';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, TextField } from '../../components/basic';
import { FormikProvider, useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useCreateUserMutation } from '../../redux/services/user';
import passwordSchemaGenerator from '../../utils/passwordschemagenerator.utils';
import passwordConfig from './password.policy.json';
import PasswordPolicyPopper from './passwordpolicy.popper/passwordpolicypopper';
import passwordPolicyTextGenerator from '../../utils/passwordploicytext.utils';
import * as PATHS from '../../constants/paths';
import { useAuth } from 'react-oidc-context';
import Snackbar from '@mui/material/Snackbar';

const SignUpPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const auth = useAuth();

  let passwordPolicyPoints = passwordPolicyTextGenerator(passwordConfig);
  let schema = passwordSchemaGenerator(passwordConfig);

  const [createUser, { isSuccess: isUserCreated }] = useCreateUserMutation();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  useEffect(() => {
    if (isUserCreated) {
      // navigate(PATHS.CREATE_ORGANISATION);
      auth.signinRedirect();
    }
  }, [isUserCreated]);

  yup.addMethod(yup.string, 'passwordValidation', validatePassword);
  yup.addMethod(
    yup.string,
    'confrimPasswordValidation',
    validateConfrimPassword,
  );
  const userSignupValidationSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    middleName: yup.string().required('Middle Name is required'),
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup.string().passwordValidation(''),
    confrimPassword: yup
      .string()
      .required('Confirm Password is required')
      .confrimPasswordValidation(''),
    profilePictureUrl: yup.string(),
  });

  function validatePassword(message) {
    return this.test('passwordValidation', message, function (value) {
      const { path, createError } = this;
      let passwordValidationErrors = schema.validate(value, { details: true });
      if (passwordValidationErrors.length > 0) {
        return createError({
          path,
          message: 'Match password policy',
        });
      }
      return true;
    });
  }

  function validateConfrimPassword(message) {
    return this.test('confrimPasswordValidation', message, function (value) {
      const { path, createError } = this;
      if (value !== formik.values.password) {
        return createError({ path, message: 'Must Match Password' });
      }
      return true;
    });
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confrimPassword: '',
      middleName: '',
      profilePictureUrl: '',
    },
    validateOnChange: true,
    validationSchema: userSignupValidationSchema,
    onSubmit: async (values) => {
      let response = await createUser({
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        profilePictureUrl: '',
      });
      handleSnackBar(response);
    },
  });

  const handleSnackBar = (response) => {
    if (response && response.data) {
      setMessage('User created');
      setOpen(true);
    } else if (response && response.error) {
      setMessage(response?.error?.data?.message);
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography marginBottom={2} marginTop={2}>
          Rikroot
        </Typography>
      </Grid>
      <Grid
        container
        className={classes.formContainer}
        marginBottom={3}
        lg={4}
        md={5}
        sm={6}
        xs={10}
      >
        <form
          onSubmit={formik.handleSubmit}
          style={{ width: '100%', heigth: '100%' }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            lg={12}
          >
            <Grid item>
              <Typography className={classes.formTitle}>
                Sign up your account
              </Typography>
            </Grid>
            <Grid container lg={8} md={8} sm={8} xs={8} marginBottom={4}>
              <TextField
                id="firstName"
                label="Name"
                value={formik.values.firstName}
                // value={fieldStatusObj.passwordField}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                required
              />
            </Grid>
            <Grid container lg={8} md={8} sm={8} xs={8} marginBottom={4}>
              <TextField
                id="middleName"
                label="Middle Name"
                value={formik.values.middleName}
                onChange={formik.handleChange}
                error={
                  formik.touched.middleName && Boolean(formik.errors.middleName)
                }
                required
              />
            </Grid>
            <Grid container lg={8} md={8} sm={8} xs={8} marginBottom={4}>
              <TextField
                id="lastName"
                label="Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                required
              />
            </Grid>

            <Grid container lg={8} md={8} sm={8} xs={8} marginBottom={2}>
              <TextField
                id="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                required
              />
            </Grid>
            <Grid container lg={8} md={8} sm={8} xs={8} marginBottom={4}>
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-end"
                marginBottom={0.4}
              >
                <PasswordPolicyPopper
                  passwordPolicyPoints={passwordPolicyPoints}
                />
              </Grid>
              <TextField
                id="password"
                label="Password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={(e) => {
                  formik.setFieldValue('password', e.target.value);
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={
                  (formik.touched.password && formik.errors.password) || ''
                }
                required
              />
            </Grid>
            <Grid container lg={8} md={8} sm={8} xs={8} marginBottom={4}>
              <TextField
                id="confrimPassword"
                label="Confrim Password"
                name="confrimPassword"
                type="password"
                // style={{ width: 300 }}
                value={formik.values.confrimPassword}
                onChange={(e) => {
                  formik.setFieldValue('confrimPassword', e.target.value);
                }}
                error={
                  formik.touched.confrimPassword &&
                  Boolean(formik.errors.confrimPassword)
                }
                helperText={
                  (formik.touched.confrimPassword &&
                    formik.errors.confrimPassword) ||
                  ''
                }
                required
              />
            </Grid>
            <Grid
              container
              lg={8}
              marginBottom={4}
              direction="row"
              justifyContent="center"
            >
              <Button variant="contained" type="submit" color="primary">
                Create Account
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {open ? (
        <Snackbar
          error
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
        />
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default SignUpPage;
