import colors from '../colors';
import fontWeights from '../fontWeights';

const muiInputLabel = {
  styleOverrides: {
    root: {
      color: `${colors.neutralLight.N200} !important`,
      fontSize: '0.857rem',
      fontWeight: fontWeights.semiBold,
      transform: 'translate(0px, -18px) scale(1)',
    },
    asterisk: {
      color: `${colors.red.R400} !important`,
    },
  },
};

export default muiInputLabel;
