import fontWeights from '../fontWeights';
import colors from '../colors';

const muiChip = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    label: {
      padding: '0 6px',
      fontSize: '1rem',
      fontWeight: fontWeights.regular,
      lineHeight: '1.429rem',
    },
    labelMedium: {
      padding: '0 10px',
    },
    root: {
      borderRadius: 100,
    },
    colorDefault: {
      color: colors.neutralLight.N700,
      backgroundColor: colors.neutralLight.N20,
    },
    sizeSmall: {
      height: 'max-content',
    },
    icon: {
      marginRight: -2,
      color: 'inherit',
    },
    iconSmall: {
      fontSize: '1rem',
    },
    iconMedium: {
      fontSize: '1.2rem',
    },
    deleteIcon: {
      color: 'inherit',
      margin: 0,
      marginLeft: -4,
      padding: 4,
      fontSize: '.8rem',
      boxSizing: 'content-box',
    },
  },
};

export default muiChip;
