import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { JD_SCRAPER_API_PATH } from '../../../constants/apiPaths';
import {
  getJobListFactory,
  getOrgsFactory,
  getKeysFacotry,
  getJobDescriptionFactory,
  getSiteConfigsFactory,
  getJDFactory,
  addSiteConfigFactory,
  getSiteConfigFactory,
  editSiteConfigFactory,
} from './endpoints';

export const jdScraperApi = createApi({
  reducerPath: 'jdScraperApi',
  baseQuery: fetchBaseQuery({
    baseUrl: JD_SCRAPER_API_PATH,
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrgs: getOrgsFactory(builder),
    getJobList: getJobListFactory(builder),
    getKeys: getKeysFacotry(builder),
    getJD: getJDFactory(builder),
    getSiteConfigs: getSiteConfigsFactory(builder),
    addSiteConfigs: addSiteConfigFactory(builder),
    getSiteConfig: getSiteConfigFactory(builder),
    editSiteConfig: editSiteConfigFactory(builder),
  }),
});

export const {
  useGetOrgsQuery,
  useLazyGetJobListQuery,
  useGetKeysQuery,
  useLazyGetKeysQuery,
  useLazyGetJDQuery,
  useGetSiteConfigsQuery,
  useAddSiteConfigsMutation,
  useGetSiteConfigQuery,
  useLazyGetSiteConfigQuery,
  useEditSiteConfigMutation,
} = jdScraperApi;
