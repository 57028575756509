import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { JOB_PROFILE_API_PATH } from '../../../constants/apiPaths';
import { createJobProfileEpFactory } from './endpoints';
import { User } from 'oidc-client-ts';
import { AUTHORITY, CLIENT_ID } from '../../../constants/auth';
import getJobProfilesEpFactory from './endpoints/getJobProfilesEpFactory';

function getUser() {
  const oidcUser = sessionStorage.getItem(
    `oidc.user:${AUTHORITY}:${CLIENT_ID}`,
  );
  if (!oidcUser) {
    return null;
  }

  return User.fromStorageString(oidcUser);
}

export const jobProfileApi = createApi({
  reducerPath: 'jobProfileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${JOB_PROFILE_API_PATH}/api/v1`,
    prepareHeaders: (headers) => {
      const user = getUser();
      const token = user?.access_token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createJobProfile: createJobProfileEpFactory(builder),
    getJobProfiles: getJobProfilesEpFactory(builder),
  }),
});

export const {
  useCreateJobProfileMutation,
  useGetJobProfilesQuery,
  useLazyGetJobProfilesQuery,
} = jobProfileApi;
