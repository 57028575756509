const muiAutoComplete = {
  styleOverrides: {
    root: {
      padding: 8,
      paddingLeft: 12,
      paddingRight: 12,
      '& .MuiOutlinedInput-root': {
        padding: '0 !important',
        paddingRight: '14px !important',
      },
    },
  },
};

export default muiAutoComplete;
