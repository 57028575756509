export default (builder) =>
  builder.query({
    query: (id) => {
      return {
        url: `organisation`,
        method: 'GET',
        params: { id },
      };
    },
  });
