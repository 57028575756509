import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LICENSE_API_PATH } from '../../../constants/apiPaths';
import {
  licenseTiersDataEpFactory,
  featureLimitsEpFactory,
  deleteLicenseTierEpFactory,
  editLicenseTierEpFactory,
  addLicenseTierEpFactory,
  getLicenseTierEpFactory,
} from './endpoints';

export const licenseApi = createApi({
  reducerPath: 'licenseApi',
  baseQuery: fetchBaseQuery({ baseUrl: LICENSE_API_PATH }),
  endpoints: (builder) => ({
    getLicenseTiersData: licenseTiersDataEpFactory(builder),
    getFeatureLimits: featureLimitsEpFactory(builder),
    deleteLicenseTier: deleteLicenseTierEpFactory(builder),
    editLicenseTier: editLicenseTierEpFactory(builder),
    addLicenseTier: addLicenseTierEpFactory(builder),
    getLicenseTier: getLicenseTierEpFactory(builder),
  }),
});

export const {
  useGetLicenseTiersDataQuery,
  useLazyGetLicenseTiersDataQuery,
  useGetFeatureLimitsQuery,
  useLazyGetFeatureLimitsQuery,
  useDeleteLicenseTierMutation,
  useEditLicenseTierMutation,
  useAddLicenseTierMutation,
  useGetLicenseTierQuery,
  useLazyGetLicenseTierQuery,
} = licenseApi;
