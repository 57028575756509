import colors from '../colors';

const muiRating = {
  defaultProps: {},
  styleOverrides: {
    root: {
      color: colors.neutralLight.N500,
    },
  },
};

export default muiRating;
