import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { USER_API_PATH } from '../../../constants/apiPaths';
import {
  createUserEpFactory,
  deleteUserEpFactory,
  getUserEpFactory,
  updateUserEpFactory,
  usersListEpFactory,
  organisationUsersEmailIdsEpFactory,
} from './endpoints';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${USER_API_PATH}/api/v1/auth`,
  }),
  endpoints: (builder) => ({
    createUser: createUserEpFactory(builder),
    deleteUser: deleteUserEpFactory(builder),
    getUser: getUserEpFactory(builder),
    updateUser: updateUserEpFactory(builder),
    getUsersList: usersListEpFactory(builder),
    getOrganisationUsersEmailIds: organisationUsersEmailIdsEpFactory(builder),
  }),
});

export const {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useGetUsersListQuery,
  useLazyGetUsersListQuery,
  useGetOrganisationUsersEmailIdsQuery,
  useLazyGetOrganisationUsersEmailIdsQuery,
} = userApi;
