import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    justifyContent: 'space-between !important',
  },
  buttonActive: {
    color: theme.palette.neutralLight.N0,
    backgroundColor: theme.palette.neutralLight.N700,
  },
  buttonEndIcon: {
    marginRight: '2px !important',
    marginLeft: '10px !important',
  },
  popper: {
    width: '100%',
  },
  popperContent: {
    marginTop: 4,
    paddingBottom: 8,
    boxShadow: theme.shadows[2],
    borderRadius: 3,
    backgroundColor: theme.palette.neutralLight.N0,
  },
}));
