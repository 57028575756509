import { Navigate } from 'react-router-dom';
import * as PATHS from '../constants/paths';
import { useAuth } from 'react-oidc-context';
import jwtDecode from '../utils/decodeJwt.utils';
import isAuthorised from '../utils/isAuthorised.utils';

const navigateTo = (path) => <Navigate to={path} />;

const withAuth = (Component, authorisedRoles = [], navigateIfUnauthorised) => {
  return function Wrapper(props) {
    const auth = useAuth();

    if (auth.isLoading) return <></>;

    if (!auth.isLoading && !auth.isAuthenticated) {
      return navigateTo(PATHS.LOGIN);
    }

    // grabbing the access token and getting the roles of the logged-in user
    const accessToken = auth?.user?.access_token;
    const decodedJwtValue = jwtDecode(accessToken);
    const userRoles = decodedJwtValue?.resource_access['rikroot-app'].roles;

    if (!isAuthorised(userRoles, authorisedRoles)) {
      return navigateIfUnauthorised ? navigateTo(PATHS.UNAUTHORISED) : <></>;
    }

    return <Component {...props} />;
  };
};

const getWithAuth = (
  component,
  authorisedRoles,
  props,
  navigateIfUnauthorised = false,
) => {
  const ComponentWithAuth = withAuth(
    component,
    authorisedRoles,
    navigateIfUnauthorised,
  );
  return <ComponentWithAuth {...props} />;
};

export default getWithAuth;
