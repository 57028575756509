import { createTheme } from '@mui/material/styles';
import fontWeights from './fontWeights';
import shadows from './shadows';
import palette from './palette';
import typography from './components/typography';
import muiCssBaseline from './components/muiCssBaseline';
import muiButton from './components/muiButton';
import muiChip from './components/muiChip';
import muiTextField from './components/muiTextField';
import muiInputBase from './components/muiInputBase';
import muiInputLabel from './components/muiInputLabel';
import muiOutlinedInput from './components/muiOutlinedInput';
import muiCheckbox from './components/muiCheckbox';
import muiAutoComplete from './components/muiAutoComplete';
import muiPaper from './components/muiPaper';
import muiRating from './components/muiRating';
import muiFormControlLabel from './components/muiFormControlLabel';
import muiIconButton from './components/muiIconButton';
import muiLinearProgress from './components/muiLinearProgress';
import muiToggleButtonGroup from './components/muiToggleButtonGroup';
import muiToggleButton from './components/muiToggleButton';
import muiPagination from './components/muiPagination';
import muiPaginationItem from './components/muiPaginationItem';
import muiFormHelperText from './components/muiFormHelperText';

export const themeOptions = {
  fontWeights,
  palette,
  shadows,
  typography,
  components: {
    MuiCssBaseline: muiCssBaseline,
    MuiAutocomplete: muiAutoComplete,
    MuiCheckbox: muiCheckbox,
    MuiOutlinedInput: muiOutlinedInput,
    MuiInputLabel: muiInputLabel,
    MuiInputBase: muiInputBase,
    MuiTextField: muiTextField,
    MuiChip: muiChip,
    MuiButton: muiButton,
    MuiPaper: muiPaper,
    MuiPagination: muiPagination,
    MuiPaginationItem: muiPaginationItem,
    MuiFormControlLabel: muiFormControlLabel,
    MuiFormHelperText: muiFormHelperText,
    MuiRating: muiRating,
    MuiIconButton: muiIconButton,
    MuiLinearProgress: muiLinearProgress,
    MuiToggleButtonGroup: muiToggleButtonGroup,
    MuiToggleButton: muiToggleButton,
  },
};

const theme = createTheme(themeOptions);

export default theme;
