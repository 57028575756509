import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ORGANISATION_API_PATH } from '../../../constants/apiPaths';
import {
  organisationsListEpFactory,
  organisationDetailsEpFactory,
  createOrganisationEpFactory,
  deleteOrganisationEpFactory,
  editOrganisationEpFactory,
} from './endpoints';

export const organisationApi = createApi({
  reducerPath: 'organisationsApi',
  baseQuery: fetchBaseQuery({ baseUrl: ORGANISATION_API_PATH }),
  endpoints: (builder) => ({
    getOrganisationsList: organisationsListEpFactory(builder),
    getOrganisationDetails: organisationDetailsEpFactory(builder),
    createOrganisation: createOrganisationEpFactory(builder),
    deleteOrganisation: deleteOrganisationEpFactory(builder),
    editOrganisation: editOrganisationEpFactory(builder),
  }),
});

export const {
  useGetOrganisationsListQuery,
  useLazyGetOrganisationsListQuery,
  useGetOrganisationDetailsQuery,
  useLazyGetOrganisationDetailsQuery,
  useCreateOrganisationMutation,
  useDeleteOrganisationMutation,
  useEditOrganisationMutation,
} = organisationApi;
