import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { Button } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import TextField from '../textfield';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '../checkbox';
import { useStyles } from './dropdown.style';
import Typography from '../typography';
import clsx from 'clsx';
import { Box, Grid } from '@mui/material';
import _ from 'lodash';
import { color } from '@mui/system';

const StyledPopper = styled('div')(({ theme }) => ({
  width: '100% !important',
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: theme.shadows[0],
    margin: 0,
    borderRadius: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'center',
      padding: '4px 10px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent !important',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function CustomPopper(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledPopper {...other} />;
}

CustomPopper.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

const MultiselectDropdown = ({
  id,
  getButtonLabel,
  noOptionsText,
  searchPlaceholder,
  value,
  items,
  onChange,
  onInputChange,
  showSecondaryText,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pendingValue, setPendingValue] = useState(value);
  const [itemsArray, setItemsArray] = useState([...items]);
  useEffect(() => {
    setPendingValue(value);
  }, [value]);

  useEffect(() => {
    setItemsArray([...items]);
  }, [items.length]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    onChange(pendingValue);
  };

  const handleChange = (selectedValue) => {
    let check = true;
    for (let i = 0; i < pendingValue.length; i++) {
      if (pendingValue[i].label === selectedValue.label) {
        check = false;
        let arr = pendingValue.filter(
          (option) => option.label !== selectedValue.label,
        );
        let itemArray = itemsArray.map((option) => {
          let obj = { ...option };
          if (option.label === selectedValue.label) {
            // option.selected = false;
            obj = { ...option, selected: false };
          }
          return obj;
        });
        setItemsArray(itemArray);
        setPendingValue(arr);
      }
    }
    if (check) {
      let arr = pendingValue;
      let obj = { ...selectedValue, selected: true };
      // selectedValue.selected = true;
      arr.push(obj);
      let itemArray = itemsArray.map((option) => {
        let obj = { ...option };
        if (option.label === selectedValue.label) {
          // option.selected = true;
          obj = { ...option, selected: true };
        }
        return obj;
      });
      setItemsArray(itemArray);
      setPendingValue(arr);
    }
  };

  const open = Boolean(anchorEl);
  const dropdownId = open ? id : undefined;

  return (
    <React.Fragment>
      <Button
        className={clsx(classes.button, { [classes.buttonActive]: open })}
        classes={{
          endIcon: classes.buttonEndIcon,
        }}
        variant="contained"
        size="large"
        aria-describedby={dropdownId}
        onClick={handleClick}
        endIcon={<FontAwesomeIcon icon={faAngleDown} />}
      >
        {getButtonLabel(pendingValue)}
      </Button>
      <Popper
        id={dropdownId}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.popperContent}>
            <Autocomplete
              open
              multiple
              onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason, param) => {
                if (
                  event.type === 'keydown' &&
                  event.key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                handleChange(param.option);
              }}
              disableCloseOnSelect
              PopperComponent={CustomPopper}
              renderTags={() => null}
              noOptionsText={noOptionsText}
              renderOption={(props, option) => (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Checkbox checked={option.selected} />
                    </Grid>
                    <Grid item>
                      <Box>
                        <Typography variant="body1">{option.label}</Typography>
                      </Box>
                      {showSecondaryText && (
                        <Box>
                          <Typography variant="subtitle2">
                            {option.secondaryText || ''}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </li>
              )}
              options={[...itemsArray].sort((a, b) => {
                // Display the selected labels first.
                let ai = value.indexOf(a);
                ai = ai === -1 ? value.length + items.indexOf(a) : ai;
                let bi = value.indexOf(b);
                bi = bi === -1 ? value.length + items.indexOf(b) : bi;
                return ai - bi;
              })}
              // getOptionLabel={(option) => option.label}
              onInputChange={onInputChange}
              renderInput={(params) => (
                <TextField
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FontAwesomeIcon icon={faSearch} />
                      </InputAdornment>
                    ),
                  }}
                  autoFocus
                  placeholder={searchPlaceholder}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
};

MultiselectDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  getButtonLabel: PropTypes.func.isRequired,
  noOptionsText: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  showSecondaryText: PropTypes.bool,
};

MultiselectDropdown.defaultProps = {
  noOptionsText: 'No options',
  searchPlaceholder: 'Search',
  value: [],
  onChange: () => {},
  onInputChange: () => {},
  showSecondaryText: false,
};

export default MultiselectDropdown;
