import React from 'react';
import { useStyles } from './app.footer.style';
import { Link, Grid, Container } from '@mui/material';
import PropTypes from 'prop-types';

const AppFooter = ({ innerRef }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} ref={innerRef}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item>
            <Link className={classes.link} href="/help">
              Help
            </Link>
          </Grid>
          <Grid item>
            <Link className={classes.link} href="/feedback">
              Send Feedback
            </Link>
          </Grid>
          <Grid item>
            <Link className={classes.link} href="/privacy-policy">
              Privacy
            </Link>
          </Grid>
          <Grid item>
            <Link className={classes.link} href="/terms">
              Terms
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

AppFooter.propTypes = {
  innerRef: PropTypes.any,
};

export default React.forwardRef((props, ref) => (
  <AppFooter innerRef={ref} {...props} />
));
