import colors from './colors';
import { createTheme } from '@mui/material/styles';

let { palette } = createTheme();

palette = {
  ...palette,
  type: 'light', // this should be 'mode' instead of 'type'
  default: {
    main: colors.neutralLight.N20,
  },
  primary: {
    main: colors.blue.B400,
  },
  secondary: {
    main: colors.neutralLight.N20,
  },
  warning: {
    main: colors.yellow.Y300,
  },
  error: {
    main: colors.red.R400,
  },
  success: {
    main: colors.green.G400,
  },
  info: {
    main: colors.neutralLight.N500,
  },
  ...colors,
};

export default palette;
