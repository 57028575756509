const {
  REACT_APP_PATH_SUNDRIES_API,
  REACT_APP_PATH_ML_API,
  REACT_APP_PATH_PEOPLE_API,
  REACT_APP_PATH_USER_API,
  REACT_APP_PATH_JOB_PROFILE_API,
  REACT_APP_PATH_SMTP_API,
  REACT_APP_PATH_JDSCRAPER_API,
  REACT_APP_PATH_EMAIL_API,
  REACT_APP_PATH_LICENSE_API,
  REACT_APP_PATH_ORGANISATION_API,
} = process.env;

export const SUNDRIES_API_PATH = REACT_APP_PATH_SUNDRIES_API;
export const ML_API_PATH = REACT_APP_PATH_ML_API;
export const PEOPLE_API_PATH = REACT_APP_PATH_PEOPLE_API;
export const JOB_PROFILE_API_PATH = REACT_APP_PATH_JOB_PROFILE_API;
export const USER_API_PATH = REACT_APP_PATH_USER_API;
export const SMTP_API_PATH = REACT_APP_PATH_SMTP_API;
export const JD_SCRAPER_API_PATH = REACT_APP_PATH_JDSCRAPER_API;
export const EMAIL_API_PATH = REACT_APP_PATH_EMAIL_API;
export const LICENSE_API_PATH = REACT_APP_PATH_LICENSE_API;
export const ORGANISATION_API_PATH = REACT_APP_PATH_ORGANISATION_API;
