import colors from '../colors';

const muiOutlinedInput = {
  styleOverrides: {
    notchedOutline: {
      borderWidth: 2,
      borderRadius: 3,
      borderColor: colors.neutralLight.N40,
    },
    root: ({ _, theme }) => ({
      transition: theme.transitions.create(
        ['background-color', 'borderColor'],
        {
          duration: theme.transitions.duration.standard,
        },
      ),
      backgroundColor: colors.neutralLight.N10,
      '&:hover': {
        backgroundColor: colors.neutralLight.N30,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.neutralLight.N40,
        },
      },
      '&.Mui-focused ': {
        backgroundColor: colors.neutralLight.N0,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.blue.B100,
        },
      },
      '& .MuiInputAdornment-root': {
        fontSize: '1.285rem',
        color: colors.neutralLight.N500,
        height: 'auto',
      },
      '& .MuiInputAdornment-positionStart': {
        marginLeft: -6,
        marginRight: 0,
      },
    }),
  },
};

export default muiOutlinedInput;
