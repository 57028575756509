import colors from '../colors';

const muiInputBase = {
  styleOverrides: {
    root: {
      fontSize: '1rem',
      lineHeight: '1.429rem',
    },
    input: {
      padding: '10px 8px !important',
      color: colors.neutralLight.N900,
    },
    inputSizeSmall: {
      padding: '6px 8px !important',
    },
    inputMultiline: {
      padding: '0 !important',
    },
  },
};

export default muiInputBase;
