import React from 'react';
import { Typography } from '../../components/basic';
// import PropTypes from 'prop-types';

const ErrorPage = () => {
  return <Typography variant="h2">Something went wrong !</Typography>;
};

ErrorPage.propTypes = {};

export default ErrorPage;
