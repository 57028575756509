import colors from '../colors';
import fontWeights from '../fontWeights';

const typography = {
  fontFamily: 'Open Sans',
  textTransform: 'none',
  fontSize: 14,
  htmlFontSize: 14,
  h1: {
    color: colors.neutralLight.N800,
    fontSize: '2.071rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '2.286rem',
  },
  h2: {
    color: colors.neutralLight.N800,
    fontSize: '1.714rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '2rem',
  },
  h3: {
    color: colors.neutralLight.N800,
    fontSize: '1.429rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '1.714rem',
  },
  h4: {
    color: colors.neutralLight.N800,
    fontSize: '1.143rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '1.429rem',
  },
  h5: {
    color: colors.neutralLight.N800,
    fontSize: '1rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '1.143rem',
  },
  h6: {
    color: colors.neutralLight.N800,
    fontSize: '0.857rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '1.143rem',
  },
  subtitle1: {
    color: colors.neutralLight.N200,
    fontSize: '0.857rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '1.143rem',
  },
  subtitle2: {
    color: colors.neutralLight.N200,
    fontSize: '0.786rem',
    fontWeight: fontWeights.bold,
    lineHeight: '1.143rem',
  },
  button: {
    fontSize: '1rem',
    fontWeight: fontWeights.semiBold,
    lineHeight: '1.429rem',
    textTransform: 'none',
  },
  body1: {
    color: colors.neutralLight.N800,
    fontSize: '1rem',
    fontWeight: fontWeights.regular,
    lineHeight: '1.429rem',
  },
  body2: {
    color: colors.neutralLight.N200,
    fontSize: '1rem',
    fontWeight: fontWeights.regular,
    lineHeight: '1.429rem',
  },
  caption: {
    color: colors.neutralLight.N200,
    fontSize: '0.786rem',
    fontWeight: fontWeights.regular,
    lineHeight: '1rem',
  },
};

export default typography;
