import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ML_API_PATH } from '../../../constants/apiPaths';
import { jdParserEpFactory } from './endpoints';

export const mlApi = createApi({
  reducerPath: 'mlApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${ML_API_PATH}/api/v1` }),
  endpoints: (builder) => ({
    parseJd: jdParserEpFactory(builder),
  }),
});

export const { useLazyParseJdQuery } = mlApi;
