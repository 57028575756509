const muiTextField = {
  defaultProps: {
    fullWidth: true,
    InputProps: {
      notched: false,
    },
    InputLabelProps: {
      shrink: false,
    },
  },
};

export default muiTextField;
