export default (builder) =>
  builder.query({
    query: (text) => ({
      url: `skills/search?input=${text}`,
      method: 'GET',
    }),
    transformResponse: (response) => {
      return response.map((skill) => ({
        ...skill,
        type: 'primarySkill',
        weight: 7,
      }));
    },
  });
